import { ChakraProvider } from '@chakra-ui/react'
import "@fontsource/open-sans/700.css"
import React from 'react'
import Layout from './components/layout'
import theme from "./theme"

export const wrapPageElement = ({ element }) => {
    return (
        <ChakraProvider theme={theme} resetCSS>
            <Layout>{element}</Layout>
        </ChakraProvider>
    )
}
